import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
// import ReactPlayer from 'react-player'

// import Frame from 'assets/icons/frame.svg'
import { headerBg } from 'styles/headerBg'
import { Container } from 'components/Container'
import { backgroundColors, colors } from 'styles/colors'
import { displayWidth } from 'styles/width'
import { mobileAfterBorder } from 'styles/mobileAfterBorder'
import { TitleH1 } from 'components/TitleComponent'
import { getDataByLanguage } from 'utils/getDataByLanguage'
import { Button } from 'components/Button'
import { getImageByImageName } from 'utils/getImageByImageName'
import { LocalizedLink } from 'i18n/LocalizedLink'
import { VideoBlock } from 'blocks/Video'

const CadWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: ${backgroundColors.contact};
    position: relative;
    border-bottom: 1px solid ${colors.dark};
    ${mobileAfterBorder};
    :before {
        ${headerBg}
    }
`
const SubTitle = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.4px;
    color: ${colors.dark};
    text-align: center;
    display: none;
    @media (min-width: ${displayWidth.tablet}) {
        display: block;
    }
`
const HeroColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 32px;
    justify-content: space-between;
    position: relative;
    @media (min-width: ${displayWidth.tablet}) {
        padding: 0 32px;
        align-items: flex-start;
        border-right: 1px solid ${colors.dark};
    }
`
const TitleStyled = styled(TitleH1)`
    margin: 40px 0 10px;
    font-size: 48px;
    line-height: 55px;
    text-align: center;
    letter-spacing: 0.67px;
    @media (min-width: ${displayWidth.tablet}) {
        margin: 20px 0;
    }
    @media (min-width: ${displayWidth.desktop}) {
        margin: 80px 0 48px;
    }
`
const InstructionColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 32px;
`

const InstructionText = styled.div`
    ul {
        padding-left: 15px;
    }
    li {
        margin: 20px;
        list-style: none;
        display: flex;
        align-items: flex-start;
    }
    strong {
        font-weight: bold;
    }
    p {
        display: inline-block;
        color: #000;
        font-size: 16px;
    }
    h2 {
        font-family: 'Yeseva One', sans-serif;
        display: inline-block;
        color: #000;
        font-size: 30px;
        font-weight: 700;
        margin: 10px 30px 30px 0;
        padding-left: 10px;
        border-left: 8px solid #5f7db5;
    }
    .circle {
        display: flex;
        align-items: center;
        font-size: 50px;
        color: #5f7db5;
        height: 20px;
        width: 15px;
        margin-right: 7px;
    }
`
const Image = styled(Img)<{ fluid: FluidObject }>`
    display: none;
    @media (min-width: ${displayWidth.tablet}) {
        display: block;
        width: 100%;
        height: auto;
        color: transparent;
        position: relative;
        margin-top: 30px;
        bottom: 0;
        left: 0;
    }
`

export const Cad = () => {
    const { i18n } = useTranslation()
    const data = useStaticQuery(graphql`
        query {
            allImageSharp {
                edges {
                    node {
                        fluid(srcSetBreakpoints: [800]) {
                            originalName
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            allCadYaml {
                edges {
                    node {
                        title
                        subTitle
                        instruction1
                        instruction2
                        buttonText
                        videoTitle
                        videoDescription
                        parent {
                            ... on File {
                                name
                            }
                        }
                    }
                }
            }
        }
    `)
    const cadYaml = getDataByLanguage(data.allCadYaml, i18n.language)
    const {
        title,
        subTitle,
        instruction1,
        instruction2,
        buttonText,
        videoTitle,
        videoDescription,
    } = cadYaml
    const imageFluid = getImageByImageName(data.allImageSharp, 'cactus.webp')
    return (
        <CadWrapper>
            <Container columns={'1fr'} tabletColumns={'1fr 2fr'}>
                <HeroColumn>
                    <div>
                        <TitleStyled>{title}</TitleStyled>
                        <SubTitle>{subTitle}</SubTitle>
                    </div>
                </HeroColumn>
                <InstructionColumn>
                    <InstructionText
                        dangerouslySetInnerHTML={{
                            __html: instruction1,
                        }}
                    />
                </InstructionColumn>{' '}
            </Container>
            {i18n.language === 'ru' && (
                <VideoBlock
                    title={videoTitle}
                    description={videoDescription}
                    url={'https://www.youtube.com/watch?v=--tKT7UZg1o'}
                />
            )}
            {i18n.language === 'uk' && (
                <VideoBlock
                    title={videoTitle}
                    description={videoDescription}
                    url={'https://youtu.be/h_XvEjon2M0'}
                />
            )}
            <Container columns={'1fr'} tabletColumns={'1fr 2fr'}>
                <HeroColumn>
                    <Image
                        fluid={imageFluid.fluid}
                        loading="eager"
                        alt="cactus image"
                        title="cactus image"
                    />
                </HeroColumn>
                <InstructionColumn>
                    <InstructionText
                        dangerouslySetInnerHTML={{
                            __html: instruction2,
                        }}
                    />
                    <LocalizedLink to={'/cad'}>
                        <Button>{buttonText}</Button>
                    </LocalizedLink>
                </InstructionColumn>
            </Container>
        </CadWrapper>
    )
}
